export default [
  // Dashboard and Member
  {
    header: 'Dashboard and Member',
    resource: 'User',
  },
  {
    title: 'แดชบอร์ด',
    route: 'dashboard-partner',
    icon: 'icon fad fa-chart-line',
    resource: 'User',
  },
  {
    title: 'รายการฝากออโต้',
    route: 'deposit',
    icon: 'icon fad fa-comments-alt-dollar',
    resource: 'User',
  },
  {
    title: 'รายการฝากโดยแอดมิน',
    route: 'deposit-fromadmin',
    icon: 'icon fad fa-comments-alt-dollar',
    resource: 'User',
  },
  {
    title: 'รายการถอน',
    route: 'withdraw',
    icon: 'icon fad fa-hand-holding-usd',
    resource: 'User',
  },
  {
    title: 'โยกเงิน',
    route: 'movecredit',
    icon: 'icon fad fa-hand-holding-usd',
    resource: 'Agadmin',
  },
  {
    title: 'รายชื่อลูกค้า',
    route: 'member',
    icon: 'icon fad fa-users-crown',
    resource: 'User',
  },
  {
    title: 'ประวัติแก้ไขข้อมูลลูกค้า',
    route: 'history-UserEditlog',
    icon: 'icon fad fa-file-alt',
    resource: 'Agadmin',
  },
  {
    title: 'ประวัติการฝากเงินซ้ำ',
    route: 'deposit-resend',
    icon: 'icon fad fa-file-alt',
    resource: 'Agadmin',
  },
  {
    title: 'ประวัติใช้งานลูกค้า',
    route: 'loguser',
    icon: 'icon fad fa-file-alt',
    resource: 'User',
  },

  {
    header: 'Dashboard Detail',
    resource: 'Agadmin',
  },
  {
    title: 'ข้อมูลธุรกรรม',
    route: 'dashboard-detail',
    icon: 'icon fad fa-chart-line',
    resource: 'Agadmin',
  },
  // {
  //   title: 'ฝาก-ถอน',
  //   route: 'deposit-withdraw',
  //   icon: 'icon fad fa-money-check-edit-alt',
  //   resource: 'Agadmin',
  // },
  {
    header: 'การตลาดเซียน',
    resource: 'marketing',
  },
  {
    title: 'รายชื่อเซียน',
    route: 'invite-friends',
    icon: 'icon fad fa-user-friends',
    resource: 'marketing',
  },
  {
    header: 'Invite Friend',
    resource: 'Agadmin',
  },
  {
    title: 'ระบบเซียน',
    route: 'invite-friend',
    icon: 'icon fad fa-user-friends',
    resource: 'Agadmin',
  },
  // {
  //   title: 'ส่งข้อความ SMS',
  //   route: 'send-sms',
  //   icon: 'icon fad fa-sms',
  //   resource: 'Agadmin',
  // },
  // {
  //   header: 'Ganeral Setting',
  //   resource: 'theme',
  // },
  // {
  //   title: 'ตั้งค่าหน้าลูกค้า',
  //   route: 'customer-setting',
  //   icon: 'icon fad fad fa-desktop-alt',
  //   resource: 'theme',
  // },
  // {
  //   title: 'ตั้งค่าค่ายเกมส์',
  //   route: 'games-setting',
  //   icon: 'icon fad fad fa-gamepad',
  //   resource: 'Agadmin',
  // },
  {
    title: 'ตั้งค่าระบบ',
    route: 'system-setting',
    icon: 'icon fad fa-cogs',
    resource: 'Admin',
  },
  {
    header: 'ระบบ',
    resource: 'User',
  },
  {
    title: 'จัดอันดับ',
    route: 'SystemRanking',
    icon: 'icon fa fa-chart-line',
    resource: 'User',
  },
  {
    title: 'คูปอง',
    route: 'SystemCoupon',
    icon: 'icon fa fa-ticket',
    resource: 'User',
  },
  {
    header: 'รวมกิจกรรม',
    resource: 'User',
  },
  {
    title: 'ทายผลฟุตบอล',
    route: 'EventsSoccerPredictionIndex',
    icon: 'icon fa fa-futbol',
    resource: 'User',
  },
  {
    title: 'ทายหวย',
    route: 'EventsLottoPredictionIndex',
    icon: 'icon fas fa-money-bill',
    resource: 'User',
  },
  {
    title: 'กิจกรรมแลกของรางวัล',
    route: 'EventsReward',
    icon: 'icon fad fa-gifts',
    resource: 'User',
  },
  {
    title: 'กิจกรรมแจกโดยแอดมิน',
    route: 'event-bonus',
    icon: 'icon fad fa-gifts',
    resource: 'User',
  },
  {
    title: 'ประวัติการรับกิจกรรม',
    route: 'event-history',
    icon: 'icon fad fa-gifts',
    resource: 'User',
  },
  {
    title: 'กิจกรรมกล่องสุ่มเพชร',
    route: 'event-point',
    icon: 'icon fad fa-gifts',
    resource: 'User',
  },
  {
    title: 'ประวัติแลกของรางวัล',
    route: 'history-redeem',
    icon: 'icon fad fa-gifts',
    resource: 'User',
  },
  {
    header: 'Lucky wheel',
    resource: 'User',
  },
  {
    title: 'ตั้งค่ากงล้อเสี่ยงโชค',
    route: 'wheel-setting',
    icon: 'icon fad fad fa-dharmachakra',
    resource: 'User',
  },
  {
    title: 'รายการเล่นกงล้อเสี่ยงโชค',
    route: 'wheel-history',
    icon: 'icon fad fad fa-dharmachakra',
    resource: 'User',
  },
  {
    header: 'Daily Login',
    resource: 'User',
  },
  {
    title: 'ตั้งค่ารางวัล Daily Login',
    route: 'dailylogin-setting',
    icon: 'icon fad fas fa-calendar-alt',
    resource: 'User',
  },
  // AG Admin Management
  // {
  //   header: 'SBOBET Setting',
  //   resource: 'SBO',
  // },
  // {
  //   title: 'โต๊ะ SBOBET',
  //   route: 'sbobet-table',
  //   icon: 'icon fad fa-trophy-alt',
  //   resource: 'SBO',
  // },
  // {
  //   title: 'ตั้งค่า SBOBET',
  //   route: 'sbobet-setting',
  //   icon: 'icon fad fa-trophy-alt',
  //   resource: 'Agadmin',
  // },

  {
    title: 'รายงาน Win/Lose',
    route: 'report',
    icon: 'icon fad fa-trophy-alt',
    resource: 'Agadmin',
  },

  {
    header: 'AG Admin Management',
    resource: 'Agadmin',
  },
  {
    title: 'ธนาคาร',
    route: 'bank',
    icon: 'icon fad fa-analytics',
    resource: 'Agadmin',
  },
  {
    title: 'ช่องทางรู้จัก',
    route: 'channel',
    icon: 'icon fad fa-ad',
    resource: 'Agadmin',
  },
  {
    title: 'จัดการผู้ดูแลระบบ',
    route: 'admin-management',
    icon: 'icon fad fa-address-card',
    resource: 'Agadmin',
  },
  {
    title: 'ประวัติการเข้าใช้งาน',
    route: 'log-admin',
    icon: 'icon fad fa-address-book',
    resource: 'Agadmin',
  },
  {
    title: 'ประวัติการอนุมัติรายการถอน',
    route: 'log-withdraw',
    icon: 'icon fad fa-address-book',
    resource: 'Agadmin',
  },
  // {
  //   title: 'จัดการบัญชีเอเย่นต์',
  //   route: 'admin-agent-management',
  //   icon: 'icon fad fa-user-secret',
  //   resource: 'Agadmin',
  // },
  // {
  //   title: 'โปรโมชั่น',
  //   route: 'promotion',
  //   icon: 'icon fad fa-gift-card',
  //   resource: 'Agadmin',
  // },
  {
    title: 'โปรโมชั่น',
    route: 'promotions',
    icon: 'icon fad fa-gift-card',
    resource: 'Agadmin',
  },
  {
    title: 'จัดการเอเย่นต์',
    route: 'setting',
    icon: 'icon fad fa-users-cog',
    resource: 'Agadmin',
  },
  {
    title: 'ประวัติหน้าจัดการเอเย่นต์',
    route: 'history',
    icon: 'icon fad fa-file-alt',
    resource: 'Agadmin',
  },
  {
    title: 'ระบบถอนออโต้ SCB',
    route: 'auto-withdraw-scb',
    icon: 'icon fad fa-hand-holding-usd',
    resource: 'Agadmin',
  },
  {
    title: 'บัญชีโยกเงิน',
    route: 'movebankcheck',
    icon: 'icon fad fa-hand-holding-usd',
    resource: 'Agadmin',
  },
  // {
  //   title: 'ระบบถอนออโต้ KBANK',
  //   route: 'auto-withdraw-kbank',
  //   icon: 'icon fad fa-hand-holding-usd',
  //   resource: 'Agadmin',
  // },
  // SMS Status
  {
    header: 'Error List',
    resource: 'User',
  },
  {
    title: 'รายการฝากไม่สำเร็จ',
    route: 'waiting-dep',
    icon: 'icon fad fa-exclamation',
    resource: 'User',
  },
  {
    title: 'รายการคืนเงิน',
    route: 'deposit-admin',
    icon: 'icon fad fa-comments-alt-dollar',
    resource: 'Agadmin',
  },
  {
    header: 'CRM Bigdata',
    resource: 'Crm',
  },
  {
    title: ' รายงานประวัติลูกค้า',
    route: 'HistoryReport',
    icon: 'icon fad fa-file-alt',
    resource: 'Crm',
  },
  {
    title: 'ยอดเล่นรายวัน',
    route: 'DailyPlayTotal',
    icon: 'icon fad fa-file-alt',
    resource: 'Crm',
  },
  {
    header: 'SMS Status',
    resource: 'User',
  },
  {
    title: 'SMS ฝาก',
    route: 'sms-deposit',
    icon: 'icon fad fa-comment-alt-dollar',
    resource: 'User',
  },
  {
    title: 'SMS ถอน',
    route: 'sms-withdraw',
    icon: 'icon fad fa-comment-alt-dollar',
    resource: 'User',
  },
  {
    title: 'SMS All',
    route: 'sms-all',
    icon: 'icon fad fa-comment-alt-dollar',
    resource: 'Agadmin',
  },
  {
    title: 'SMS OTP',
    route: 'sms-otp',
    icon: 'icon fad fa-comment-alt-exclamation',
    resource: 'User',
  },

  {
    title: 'True Money Wallet',
    route: 'true-money',
    icon: 'icon fad fa-comment-alt-lines',
    resource: 'User',
  },
  {
    title: 'KPLUS GEN',
    route: 'kplus-gen',
    icon: 'icon fad fa-university',
    resource: 'Agadmin',
  },
  {
    title: 'KBANK STATEMENT',
    route: 'kbank-statement',
    icon: 'icon fad fa-university',
    resource: 'User',
  },
  // {
  //   title: 'KBANK LIVE',
  //   route: 'kbank-live',
  //   icon: 'icon fad fa-university',
  //   resource: 'User',
  // },
  // {
  //   title: 'SCB Connect',
  //   route: 'scb-connect',
  //   icon: 'icon fad fa-university',
  //   resource: 'User',
  // },
  {
    title: 'SCB Statement',
    route: 'statement',
    icon: 'icon fad fa-money-check',
    resource: 'User',
  },
  {
    title: 'Crypto STATEMENT',
    route: 'crypto-statement',
    icon: 'icon fad fa-university',
    resource: 'User',
  },
  // กิจกรรม

  // Setting
  // {
  //   header: 'Admin Management',
  //   resource: 'Agadmin',
  // },
  // {
  //   title: 'Agent List',
  //   route: 'agent-list',
  //   icon: 'SettingsIcon',
  //   resource: 'Agadmin',
  // },
]
